import styled, { css } from 'styled-components';

export const textStyle = css`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  font-style: normal;
`;

export const H1 = styled.h1`
  margin: 0;
  font-size: 72px;
  font-weight: 700;
  line-height: 108px;
`;

export const H2 = styled.h2`
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
`;

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;
