import React, { useState } from 'react';
import Canvas from 'components/Canvas';
import { FormContainer, CloseButton, ChatWrapper, OpenButton, ImprintLink } from './styles';
import InputFields from './InputFields';
import { backendApi } from 'services/backendService';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';

export default function Form() {
  const [inputActive, setInputActive] = useState(false);
  const history = useHistory();
  const getRequest = backendApi.getState()['GET_FIELDS'];
  const { data } = useQuery(getRequest);
  if (data) {
    const { phone, username } = data.userInformations[0];
    backendApi.getState().setRequiredFields(phone, username);
  }

  const toggleInput = () => {
    setInputActive(!inputActive);
  };

  return (
    <ChatWrapper>
      <ImprintLink onClick={() => history.push('/imprint')}>Impressum/Datenschutz</ImprintLink>
      <Canvas />
      {!inputActive && <OpenButton onClick={toggleInput}>Open chat</OpenButton>}
      {inputActive && (
        <FormContainer isActive={inputActive}>
          <CloseButton onClick={toggleInput} isOpen={inputActive}>
            X
          </CloseButton>
          <InputFields />
        </FormContainer>
      )}
    </ChatWrapper>
  );
}
