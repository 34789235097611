import { useQuery } from '@apollo/client';
import React from 'react';
import { backendApi } from 'services/backendService';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const ImprintContainer = styled.div`
  margin: 10px;
  border: 1px solid black;
  border-radius: 10px 10px 10px 0;
  padding: 20px;
  max-width: 500px;
  word-break: break-all;
  hyphens: auto;
`;

export default function Imprint() {
  const getRequest = backendApi.getState()['GET_IMPRINT'];
  const { data } = useQuery(getRequest);

  return (
    <ImprintContainer>
      <ReactMarkdown>{data && data.imprints[0].text}</ReactMarkdown>
    </ImprintContainer>
  );
}
