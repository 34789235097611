import create from 'zustand';
import gql from 'graphql-tag';
import { io } from 'socket.io-client';

const environments = {
  local: 'ws://localhost:8080/',
  live: 'https://canvas-server-mfs1.onrender.com',
};

export const [useBackendStore, backendApi] = create(set => ({
  env: localStorage.getItem('ENV') ? localStorage.getItem('ENV') : environments.local,
  socket: io(environments.live),
  requestUser: false,
  requestPhone: false,
  GET_MESSAGES: gql`
    query {
      messages(last: 2) {
        id
        text
        username
        phone
        createdAt
      }
    }
  `,
  GET_ALL_MESSAGES: gql`
    query {
      messages {
        id
        text
        username
        phone
        createdAt
      }
    }
  `,
  POST_MESSAGE: gql`
    mutation createMessage($text: String!, $username: String, $phone: String) {
      createMessage(data: { text: $text, username: $username, phone: $phone }) {
        id
        text
        username
        phone
      }
    }
  `,
  GET_FIELDS: gql`
    query {
      userInformations(first: 1) {
        id
        username
        phone
      }
    }
  `,
  GET_IMPRINT: gql`
    query {
      imprints(first: 1) {
        id
        text
      }
    }
  `,
  setEnv: env => {
    set({ env: environments[env] });
    localStorage.setItem('ENV', env);
  },
  setRequiredFields: (phone, username) => {
    set({ requestPhone: phone, requestUser: username });
  },
}));
