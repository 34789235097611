import styled, { css, keyframes } from 'styled-components';
import { mediaMaxPx } from 'styles';

export const CanvasContainer = styled.div`
  overflow: auto;
  flex-grow: unset;
  padding: 40px;
  width: 100%;
  ${props =>
    props.isFullScreen &&
    css`
      max-width: 1200px;
    `}
  margin: 0 auto;
  ${mediaMaxPx(
    480,
    css`
      padding: 20px;
    `,
  )};
`;

export const Message = styled.div`
  padding: 20px;
  border: 1px solid black;
  margin: 0 0 20px 0;
  border-radius: ${props => (props.isNewest ? '20px 20px 0px 20px' : '20px 20px 20px 0')};
  position: relative;
  word-break: break-word;
  hyphens: auto;
  background-color: ${props => (props.isNewest ? '#000' : 'transparent')};
  color: ${props => (props.isNewest ? 'white' : 'black')};

  h2 {
    margin: 4px 0;
    ${mediaMaxPx(
      1400,
      css`
        font-size: 40px;
        line-height: 48px;
      `,
    )};
    ${mediaMaxPx(
      850,
      css`
        font-size: 24px;
        line-height: 32px;
      `,
    )};
    ${mediaMaxPx(
      500,
      css`
        font-size: 18px;
        line-height: 24px;
      `,
    )};
  }
`;

export const MessageBottomLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Timestamp = styled.p`
  color: #5f657a;
  bottom: 0;
  right: 0;
  text-align: end;
  margin: 10px 0 0 0;
`;

export const Username = styled.p`
  color: #5f657a;
  bottom: 0;
  left: 0;
  text-align: start;
  margin: 10px 0 0 0;
  max-width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
`;

export const CodeContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
`;

const spinButton = keyframes`
    0%{transform:  rotate(0deg) scale(1);}
    25%{transform: rotate(0deg) scale(1.02);}
    50%{transform: rotate(0deg) scale(1)}
    100%{transform: rotate(360deg)scale(1)}
`;

export const ImgContainer = styled.div`
  width: 270px;
  height: 270px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 270px;
    border-radius: 50%;
    position: absolute;
  }
  img:last-child {
    animation: ${spinButton} 3s ease-in-out 0.01s infinite;
    max-width: 290px;
  }
`;
