import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
`;

export const StyledTableCell = styled.td`
  border-right: 1px solid black;
  padding: 4px 16px;
  background-color: ${props => (props.isBad ? 'red' : 'transparent')};
`;

export const StyledTableRow = styled.tr`
  border-bottom: 1px solid black;
  outline: none;
  box-sizing: border-box;
`;

export const StyledTableHead = styled.thead`
  position: sticky;
  top: 0px;
`;

export const StyledTableBody = styled.tbody`
  overflow: auto;
  height: 90vh;
`;

export const TableColumnName = styled(StyledTableCell)`
  background-color: #efefef;
`;
