import React from 'react';
import { backendApi } from 'services/backendService';

export default function Config() {
  const socket = backendApi.getState().socket;

  const clickHandler = env => {
    backendApi.getState().setEnv(env);
  };

  const toggleSleep = toggle => {
    socket.emit('sleep', toggle);
  };

  return (
    <>
      <button onClick={() => clickHandler('local')}>Local</button>
      <button onClick={() => clickHandler('live')}>Live</button>
      <button onClick={() => toggleSleep(false)}>Sleep</button>
      <button onClick={() => toggleSleep(true)}>Active</button>
    </>
  );
}
