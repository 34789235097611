import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import Form from 'components/Form';
import Canvas from 'components/Canvas';
import Config from 'components/Config';
import Imprint from 'components/Imprint';
import History from 'components/History';

const routeRules = [
  {
    path: '/',
    component: Form,
  },
  {
    path: '/form',
    component: Form,
  },
  {
    path: '/canvas',
    component: Canvas,
  },
  {
    path: '/config',
    component: Config,
  },
  {
    path: '/imprint',
    component: Imprint,
  },
  {
    path: '/history',
    component: History,
  },
];

export default function Routes() {
  const routes = routeRules.map(route => (
    <Route key={route.path} path={route.path} component={route.component} exact />
  ));

  return (
    <BrowserRouter>
      <Switch>
        {routes}
        <Redirect key={'redirect'} to={'/'} />
      </Switch>
    </BrowserRouter>
  );
}
