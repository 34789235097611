import React from 'react';
import { useForm } from 'react-hook-form';
import { backendApi, useBackendStore } from 'services/backendService';
import { useMutation } from '@apollo/client';
import {
  StyledButton,
  InputContainer,
  StyledTextArea,
  StyledInput,
  UserContainer,
  FormError,
  InputBottomLine,
  CharCounter,
  TextAreaContainer,
} from './styles';
import moment from 'moment';

const options = {
  text: { required: { value: true, message: 'Please enter your message!' } },
  username: { required: { value: true, message: 'Please enter your username!' } },
  phone: {
    pattern: { value: /^[0-9]+$/, message: 'Please enter only numbers' },
    minLength: { value: 10, message: 'Please enter a real number.' },
    required: { value: true, message: 'Please enter your phonenumber!' },
  },
};

export default function InputFields() {
  const socket = backendApi.getState().socket;
  const postRequest = backendApi.getState()['POST_MESSAGE'];
  const [postMessage] = useMutation(postRequest);
  const needsUser = useBackendStore(state => state.requestUser);
  const needsPhone = useBackendStore(state => state.requestPhone);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = useForm();

  const submitHandler = data => {
    const { text, phone, username } = data;
    postMessage({ variables: { text, phone, username } });
    const message = { text, phone, username, createdAt: moment() };
    socket.emit('message', message);
    clearErrors();
    setValue('text', '', { shouldValidate: false });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputContainer>
        <TextAreaContainer>
          <StyledTextArea
            {...register('text', options.text)}
            placeholder="Your Message"
            maxLength={100}
            error={errors?.text}
          />
          <CharCounter>{watch('text', '').length}/100</CharCounter>
        </TextAreaContainer>
        <UserContainer>
          {needsUser && (
            <StyledInput
              {...register('username', options.username)}
              placeholder="Username"
              error={errors?.username}
            />
          )}
          {needsPhone && (
            <StyledInput
              {...register('phone', options.phone)}
              placeholder="Phone"
              error={errors?.phone}
            />
          )}
        </UserContainer>
        <InputBottomLine>
          {Object.entries(errors).length > 0 && (
            <FormError>
              {errors?.text?.message || errors?.username?.message || errors?.phone?.message}
            </FormError>
          )}
          <StyledButton type="submit">Send my message</StyledButton>
        </InputBottomLine>
      </InputContainer>
    </form>
  );
}
