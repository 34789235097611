import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html{

  }
  html, body{
    font-family: 'Space Grotesk', sans-serif;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
  }
  div {    
    box-sizing: border-box;
  }
`;

export const mediaMaxPx = (bp, style) => {
  return css`
    @media only screen and (max-width: ${bp}px) {
      ${style}
    }
  `;
};
