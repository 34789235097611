import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { backendApi } from 'services/backendService';
import { H2 } from 'styles/text';
import {
  Message,
  Timestamp,
  CanvasContainer,
  Username,
  MessageBottomLine,
  CodeContainer,
  ImgContainer,
} from './styles';
import moment from 'moment';
import { useLocation } from 'react-router';
import wordList from 'assets/wordList.txt';
import qrCodeText from 'assets/qr_code_text.png';
import qrCodeBg from 'assets/qr_code_bg.png';

export default function Canvas() {
  const socket = backendApi.getState().socket;
  const [messages, setMessages] = useState([]);
  const getRequest = backendApi.getState()['GET_MESSAGES'];
  const elem = document.documentElement;
  const location = useLocation();
  const forbiddenWords = wordList.split('\n');
  const { data } = useQuery(getRequest);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const openFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  };

  socket.on('newMessage', message => {
    const { text, createdAt, username } = message;
    const temp = [...messages, { text: text, date: createdAt, username }];
    temp.shift();
    setMessages(temp);
  });

  socket.on('sleep', toggle => {
    setIsFullScreen(toggle);
  });

  useEffect(() => {
    if (data) {
      data.messages.map(m => {
        const { text, username, phone, createdAt } = m;
        setMessages(messages => [...messages, { text, username, phone, createdAt }]);
      });
    }
  }, [data]);

  useEffect(() => {
    const keydown = window.addEventListener('keydown', e => {
      if (location.pathname == '/canvas' && e.key == 'f') openFullScreen();
    });
    return () => {
      window.removeEventListener(keydown, e => {
        if (location.pathname == '/canvas' && e.key == 'f') openFullScreen();
      });
    };
  }, []);

  const censorWord = word => {
    let censoredWord = '';
    for (let i = 0; i < word.length; i++) {
      censoredWord += '*';
    }
    return censoredWord;
  };

  const filterMessage = message => {
    const oldSentence = message.toLowerCase();
    let newSentence = oldSentence;
    for (let i = 0; i < forbiddenWords.length; i++) {
      newSentence = newSentence.replaceAll(forbiddenWords[i].toLowerCase(), () =>
        censorWord(forbiddenWords[i]),
      );
    }
    return newSentence;
  };

  return (
    <CanvasContainer isFullScreen={isFullScreen}>
      {messages &&
        messages.map((m, index) => {
          return (
            <Message key={index} isNewest={index == messages.length - 1}>
              {index == messages.length - 1 ? (
                <H2>{filterMessage(m.text)}</H2>
              ) : (
                <H2>{filterMessage(m.text)}</H2>
              )}
              <MessageBottomLine>
                <Username>{m.username}</Username>
                <Timestamp>{moment(m.createdAt).format(' HH:mm ')}</Timestamp>
              </MessageBottomLine>
            </Message>
          );
        })}
      {isFullScreen && (
        <CodeContainer>
          <ImgContainer>
            <img src={qrCodeBg} />
            <img src={qrCodeText} />
          </ImgContainer>
        </CodeContainer>
      )}
    </CanvasContainer>
  );
}
