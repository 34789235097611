import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';

export const InputContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
`;

export const UserContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
  ${mediaMaxPx(
    850,
    css`
      flex-direction: column;
    `,
  )}
`;

export const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
`;

export const StyledTextArea = styled.textarea`
  border: 1px solid ${props => (props.error ? 'red' : 'black')};
  border-radius: 0;
  height: 120px;
  width: 100%;
  max-width: 500px;
  padding: 10px 10px 20px 10px;
  box-sizing: border-box;
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const StyledInput = styled(StyledTextArea)`
  height: 40px;
  width: calc(50% - 5px);
  padding: 10px;
  margin-bottom: 10px;
  ${mediaMaxPx(
    850,
    css`
      width: 100%;
    `,
  )}
`;

export const InputBottomLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  ${mediaMaxPx(
    850,
    css`
      align-items: flex-start;
      flex-direction: column;
      span {
        margin-bottom: 10px;
      }
    `,
  )};
`;

export const FormError = styled.span`
  color: red;
  font-weight: bold;
`;

export const CharCounter = styled.p`
  color: #5f657a;
  position: absolute;
  margin: 4px;
  bottom: 0;
  right: 0;
`;

export const StyledButton = styled.button`
  border: 1px solid black;
  transition: 0.1s ease-in-out;
  background-color: white;
  height: 40px;
  width: fit-content;
  padding: 10px;
  font-size: 16px;
  margin: 0;

  &:hover {
    background-color: black;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;
