import styled, { css, keyframes } from 'styled-components';
import { mediaMaxPx } from 'styles';

export const ChatWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const expandForm = keyframes`
  0%{max-height: 0px}
  100%{max-height:500px}
`;

export const FormContainer = styled.div`
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: white;
  border-top: 1px solid black;
  animation: ${expandForm} 0.15s ease-out;
  ${mediaMaxPx(
    480,
    css`
      padding: 20px;
    `,
  )};
`;

const spinButton = keyframes`
    0%{transform:  rotate(0deg) scale(1);}
    25%{transform: rotate(0deg) scale(1.1);}
    50%{transform: rotate(0deg) scale(1)}
    100%{transform: rotate(360deg)scale(1)}
`;

export const OpenButton = styled.button`
  cursor: pointer;
  outline: none;
  background-color: #0baa78;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  padding: 0 10px;
  position: fixed;
  bottom: 80px;
  left: 40px;
  animation: ${spinButton} 3s ease-in-out 0.01s infinite;
  ${mediaMaxPx(
    480,
    css`
      left: 20px;
    `,
  )};
  &:hover {
    color: white;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  background-color: transparent;
  width: 40px;
  height: fit-content;
  padding: 0 10px;
  &:hover {
    color: #5f657a;
  }
`;

export const ImprintLink = styled.p`
  position: fixed;
  font-size: 10px;
  right: 40px;
  bottom: 0;
  ${mediaMaxPx(
    850,
    css`
      right: 20px;
    `,
  )}
`;
