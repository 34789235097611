import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './components/Routes';
import { GlobalStyle } from './styles';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';

const client = new ApolloClient({
  uri: 'https://api-eu-central-1.graphcms.com/v2/cku8an6ia3lmi01yz1rvhalk6/master',
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <Routes />
    <GlobalStyle />
  </ApolloProvider>
);
const root = document.createElement('div');
document.body.appendChild(root);

ReactDOM.render(<App />, root);

export default App;
