import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { backendApi } from 'services/backendService';
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  TableColumnName,
  StyledTableHead,
  StyledTableBody,
} from './styles';
import moment from 'moment';
import wordList from 'assets/wordList.txt';

export default function History() {
  const [messages, setMessages] = useState([]);
  const getRequest = backendApi.getState()['GET_ALL_MESSAGES'];
  const { data } = useQuery(getRequest);
  const forbiddenWords = wordList.split('\n');

  useEffect(() => {
    if (data) {
      data.messages.map(m => {
        const { text, username, phone, createdAt } = m;
        setMessages(messages => [...messages, { text, username, phone, createdAt }]);
      });
    }
  }, [data]);

  const sortByDate = () => {
    const dates = messages.map(m => {
      return moment(m.createdAt);
    });
    const sortedArray = messages.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
    setMessages(sortedArray);
  };

  const filterMessages = message => {
    const sentence = message.toLowerCase();

    for (let i = 0; i < forbiddenWords.length; i++) {
      if (!forbiddenWords[i]) continue;
      if (sentence.includes(forbiddenWords[i])) {
        return true;
      }
    }
  };

  return (
    <StyledTable>
      <StyledTableHead>
        <TableColumnName>ok?</TableColumnName>
        <TableColumnName>message</TableColumnName>
        <TableColumnName>
          date <button onClick={sortByDate}></button>
        </TableColumnName>
        <TableColumnName>time</TableColumnName>
      </StyledTableHead>
      <StyledTableBody>
        {data &&
          messages.map((m, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell isBad={filterMessages(m.text)}> </StyledTableCell>
                {m.username && <StyledTableCell>{m.username}</StyledTableCell>}
                <StyledTableCell>{m.text}</StyledTableCell>
                <StyledTableCell>{moment(m.createdAt).format('DD.MM.YYYY')}</StyledTableCell>
                <StyledTableCell>{moment(m.createdAt).format('HH:mm ')}</StyledTableCell>
              </StyledTableRow>
            );
          })}
      </StyledTableBody>
    </StyledTable>
  );
}
